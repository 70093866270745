import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { DialogData } from '../dialog-data/dialog-data'
import { RepVehCopyaddDialogServiceService } from './rep-veh-copyadd-dialog-service.service';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-rep-vehicle-copyadd-dialog',
  templateUrl: './rep-vehicle-copyadd-dialog.component.html',
  styleUrls: ['./rep-vehicle-copyadd-dialog.component.css']
})
export class RepVehicleCopyaddDialogComponent implements OnInit {
  [x: string]: any;

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //errormessage
  errorMessage: string = "";

  //hikiate
  hikiateLstFromDB: any = [];
  dummyHikiateLst: any;
  selectedHikiateLst: any = [];

  //pc spec
  pcSpecLstFromDB: any = [];
  dummyPcSpecLst: any;
  selectedPcSpecLst: any = [];


  //compPcSpec
  // comppcSpecLstFromDB: any = [];
  dummyCompPcSpecLst: any;
  selectedCompPcSpecLst: any = [];


  //ngModelValues
  copyMonthlyRep: any = "";
  copyCompBom: any = "";
  copyModelNumber: any = "";
  copyOption: any = "";
  copySapMatId: any = "";
  copyNamc: any = "";
  copyFamily: any = "";
  copyKatashiki: any = "";
  copyCustomer: any = "";
  copyDest: any = "";
  copySP: any = "";
  dummyHikiateLstToDB: any = [];//hiki
  dummyPcSpecLstToDB: any = [];//pcspec
  dummyCompPcSpecLstToDB: any = [];//comppcspec
  copyNetSpec: any = "";
  copyHundPercentSpec: any = "";
  copyCompHundPercentSpec: any = "";
  copyExtTimeIn: any = "";
  copyExtTimeOut: any = "";
  copyComments: any = "";
  CompPcSpecvalue: any = "";
  PcSpecvalue: any = "";
  hikiatevalue: any = "";
  comppcSpecLstFromDB: any = []
  netSpecData: any = []
  checkPcSpecLst: any = []
  //lst
  namcLst = [];
  famcdLst = [];
  katashikiLst = [];
  customerLst = [];
  destLst = [];

  //response from backend after insert/update records
  response: any;

  //to main page
  message = "Record Added Successfully";

  spinnerMessage = "Please Wait...";
  hidedata: any = "false";
  constructor(public dialogRef: MatDialogRef<RepVehicleCopyaddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private SpinnerService: NgxSpinnerService,
    private repVehCopyAddService: RepVehCopyaddDialogServiceService) { }
  index: any;

  async ngOnInit() {
    this.SpinnerService.show();
    let a = await this.getNamc();

    if (a) {
      console.log(this.data);
      let namc_id; let cust_id
      for (let i = 0; i < this.namcLst.length; i++) {
        if (this.data.namc == this.namcLst[i].namc_cd) {
          namc_id = this.namcLst[i].namc_lgl_entity_id

          console.log(this.copyNamc)
        }
      }
      this.copyNamc = namc_id;
      let b = await this.getCustData();
      let c = await this.getFamCdByNamc();
      if (b && c) {
        // this.copyNamc = namc_id;
        // console.log(this.copyNamc)
        for (let i = 0; i < this.customerLst.length; i++) {
          if (this.data.customerId == this.customerLst[i].buyoff_cust_nm) {
            cust_id = this.customerLst[i].buyoff_cust_id
          }
        }
      }

      this.copyMonthlyRep = this.data.monthlyRep;
      this.copyCompBom = this.data.compBom;
      this.copyModelNumber = this.data.modelNumber;
      this.copyOption = this.data.option;
      this.copySapMatId = this.data.sapMatId;

      this.copyFamily = this.data.family;
      let a = await this.getKatashikiByFamCd();
      if (a) {
        this.copyKatashiki = this.data.katashiki;
      }

      this.copyCustomer = cust_id
      let p = await this.getDestByCust();
      if (p) {
        this.copyDest = this.data.dest;
      }

      this.copySP = this.data.sp;
      //hiki
      if (this.data.hikiate != '' && this.data.hikiate[0] != '') {
        this.dummyHikiateLstToDB = this.data.hikiate;
        this.selectedHikiateLst = this.data.hikiate;
      }
      if (this.data.pcSpec != '' && this.data.pcSpec[0] != '') {
        this.dummyPcSpecLstToDB = this.data.pcSpec;//pcspec
        this.selectedPcSpecLst = this.data.pcSpec;
      }//pcspec
      if (this.data.compPCSpec != '' && this.data.compPCSpec[0] != '') {
        this.dummyCompPcSpecLstToDB = this.data.compPCSpec;//comppcspec
        this.selectedCompPcSpecLst = this.data.compPCSpec;
      }//comppcspec
      this.copyNetSpec = this.data.netSpec;
      this.copyHundPercentSpec = this.data.hundrentPerSpec;
      this.copyCompHundPercentSpec = this.data.compHundPerSpec
      this.copyExtTimeIn = this.data.extractionTimeIn;
      this.copyExtTimeOut = this.data.extractionTimeOut;
      this.copyComments = this.data.comments;

    }
    this.getCopyCompSpec();
    let d = await this.getHikiateByDest();
    if (d) {
      let hikiatelst = this.hikiateLstFromDB
      if (hikiatelst != undefined && hikiatelst != '') {
        this.dummyHikiateLstToDB.forEach(hiki => {
          for (let i = 0; i < hikiatelst.length; i++) {
            if (hiki == hikiatelst[i].hikiate) {
              hikiatelst.splice(i, 1);
            }
          }
          this.hikiateLstFromDB = hikiatelst;
        });
      }
      console.log(this.hikiateLstFromDB)
    }
    // let e = await this.getpcspecByFamily();
    // if (e) {
    //   let pcSpecLst = this.pcSpecLstFromDB
    //   if (pcSpecLst != undefined && pcSpecLst != '') {
    //     this.dummyPcSpecLstToDB.forEach(pcSPec => {
    //       for (let i = 0; i < pcSpecLst.length; i++) {
    //         if (pcSPec == pcSpecLst[i].pc_spec) {
    //           pcSpecLst.splice(i, 1);
    //         }
    //       }
    //       this.pcSpecLstFromDB = pcSpecLst;
    //     });
    //   }
    // }
    // let f = await this.getcomppcspecByFamily();
    // if (f) {
    //   let compPcSpeclst = this.comppcSpecLstFromDB
    //   if (compPcSpeclst != undefined && compPcSpeclst != '') {
    //     this.dummyCompPcSpecLstToDB.forEach(compPcSpec => {
    //       for (let i = 0; i < compPcSpeclst.length; i++) {
    //         if (compPcSpec == compPcSpeclst[i].pc_spec) {
    //           compPcSpeclst.splice(i, 1);
    //         }
    //       }
    //       this.comppcSpecLstFromDB = compPcSpeclst;
    //     });
    //   }
    // }
    this.SpinnerService.hide();

  }
  getNamc() {
    return new Promise(res => {
      this.repVehCopyAddService.getNamc().subscribe(data => {
        console.log(data);
        this.namcLst = data.payload;
        res(true)
      });
    })

  }
  getpcspecByFamily() {
    // to get pcSpec
    return new Promise(res => {
      if (this.copyFamily == undefined) {
        this.copyFamily = ""
      }
      if (this.copyFamily == "") {
        this.pcSpecLstFromDB = []
      } else {
        this.SpinnerService.show();
        this.repVehCopyAddService.getPcSPec(this.copyFamily).subscribe(data => {
          console.log(data);
          this.pcSpecLstFromDB = data.payload
          this.selectedPcSpecLst = [];
          this.SpinnerService.hide();
          res(true)
        });
      }
    })

  }

  getcomppcspecByFamily() {
    // to get pcSpec
    return new Promise(res => {
      if (this.copyFamily == undefined) {
        this.copyFamily = ""
      }
      if (this.copyFamily == "") {
        this.comppcSpecLstFromDB = []
      } else {
        this.SpinnerService.show();
        this.repVehCopyAddService.getPcSPec(this.copyFamily).subscribe(data => {
          console.log(data);
          this.comppcSpecLstFromDB = data.payload
          this.selectedCompPcSpecLst = [];
          this.SpinnerService.hide();
          res(true)
        });
      }
    })

  }

  getCustData() {
    return new Promise(res => {
      if (this.copyKatashiki == undefined) {
        this.copyKatashiki = ""
      }
      if (this.copyFamily == undefined) {
        this.copyFamily = ""
      }
      if (this.copyNamc == undefined) {
        this.copyNamc = ""
      }
      if (this.copyKatashiki == "") {
        this.customerLst = []
      }
      this.SpinnerService.show();
      this.repVehCopyAddService.getCustomer(this.copyNamc, this.copyFamily, this.copyKatashiki).subscribe(data => {
        console.log(data);
        this.customerLst = data.payload;
        this.SpinnerService.hide();
        res(true)
      });
    })
  }

  //onchange
  getFamCdByNamc() {
    return new Promise(res => {
      if (this.copyNamc == undefined) {
        this.copyNamc = ""
      }
      if (this.copyNamc == "") {
        this.famcdLst = []
        this.katashikiLst = []
        this.hikiateLstFromDB = []
        this.pcSpecLstFromDB = []
        this.comppcSpecLstFromDB = []
      } else {
        this.SpinnerService.show();
        this.repVehCopyAddService.getFamily(this.copyNamc).subscribe(data => {
          console.log(data);
          this.SpinnerService.hide();
          this.famcdLst = data.payload;
          this.copyFamily = ""
          this.copyKatashiki = ""
          this.aFamily = "";
          this.aKatashiki = "";
          this.hikiateLstFromDB = []
          this.pcSpecLstFromDB = []
          this.comppcSpecLstFromDB = []
          this.dummyHikiateLst = "";
          this.dummyCompPcSpecLst = "";
          this.dummyPcSpecLst = "";
          this.selectedHikiateLst = [];
          this.selectedPcSpecLst = [];
          this.selectedCompPcSpecLst = [];
          res(true)
        });
      }
    });
  }

  getKatashikiByFamCd() {
    return new Promise(res => {
      if (this.copyFamily == undefined) {
        this.copyFamily = ""
      }
      this.SpinnerService.show();
      this.repVehCopyAddService.getKatashiki(this.copyFamily).subscribe(async data => {
        this.katashikiLst = data.payload;
        this.SpinnerService.hide();
        this.copyKatashiki = ""
        this.dummyHikiateLst = "";
        this.dummyCompPcSpecLst = "";
        this.dummyPcSpecLst = "";
        this.selectedHikiateLst = [];
        this.selectedPcSpecLst = [];
        this.selectedCompPcSpecLst = [];
        let x = await this.getpcspecByFamily();
        let y = await this.getcomppcspecByFamily()
        if (x && y) {
          res(true)
        }
      });
    })

  }

  getCustByKatashiki(): void {
    if (this.copyKatashiki == undefined) {
      this.copyKatashiki = ""
    }
    if (this.copyFamily == undefined) {
      this.copyFamily = ""
    }
    if (this.copyNamc == undefined) {
      this.copyNamc = ""
    }
    this.SpinnerService.show();
    this.repVehCopyAddService.getCustomer(this.copyNamc, this.copyFamily, this.copyKatashiki).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.customerLst = data.payload;
      this.copyCustomer = ""
    });
  }

  getDestByCust() {
    return new Promise(res => {
      if (this.copyKatashiki == undefined) {
        this.copyKatashiki = ""
      }
      if (this.copyFamily == undefined) {
        this.copyFamily = ""
      }
      if (this.copyNamc == undefined) {
        this.copyNamc = ""
      }
      if (this.copyCustomer == undefined) {
        this.copyCustomer = ""
      }
      this.SpinnerService.show();
      this.repVehCopyAddService.getDest(this.copyNamc, this.copyFamily, this.copyKatashiki, this.copyCustomer).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.destLst = data.payload;
        this.copyDest = ""
        res(true)

      })
    })


  }

  getHikiateByDest() {
    if (this.copyFamily == undefined) {
      this.copyFamily = ""
    }
    if (this.copyNamc == undefined) {
      this.copyNamc = ""
    }
    this.SpinnerService.show();
    return new Promise(res => {
      this.repVehCopyAddService.getHikiate(this.copyFamily, this.copyNamc).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.hikiateLstFromDB = data.payload
        this.hikiatevalue = ""
        res(true)
      })
    });
  }


  //hikiate
  moveHikiForward() {
    let hikiatelst = this.hikiateLstFromDB
    console.log(this.dummyHikiateLst);

    this.dummyHikiateLst.forEach(hiki => {
      this.selectedHikiateLst.push(hiki);
      for (let i = 0; i < hikiatelst.length; i++) {
        if (hiki == hikiatelst[i].hikiate) {
          hikiatelst.splice(i, 1);
        }
      }
    });
    this.hikiateLstFromDB = hikiatelst;
    this.dummyHikiateLstToDB = this.selectedHikiateLst;
    this.dummyHikiateLst = ""
  }
  moveHikiBackward() {
    this.dummyHikiateLstToDB.forEach(hiki => {
      let body = {
        "hikiate": hiki
      }
      this.hikiateLstFromDB.push(body);
      this.index = this.selectedHikiateLst.indexOf(hiki);
      this.selectedHikiateLst.splice(this.index, 1);
    });
    if (this.dummyHikiateLstToDB.length >= 1) {
      this.hikiateLstFromDB = this.hikiateLstFromDB.sort((a, b) => a.hikiate > b.hikiate ? 1 : -1);
      // this.dummyHikiateLstToDB = this.selectedHikiateLst;
      this.dummyHikiateLstToDB = []
    }
  }

  movePcSPecForward() {
    let pcSpecLst = this.pcSpecLstFromDB
    console.log(this.dummyPcSpecLst);
    this.dummyPcSpecLst.forEach(pcSPec => {
      this.selectedPcSpecLst.push(pcSPec);
      for (let i = 0; i < pcSpecLst.length; i++) {
        console.log(i);
        console.log(pcSPec, pcSpecLst[i].pc_spec);
        console.log(pcSPec === pcSpecLst[i].pc_spec);
        console.log(i);
        if (pcSPec == pcSpecLst[i].pc_spec) {
          pcSpecLst.splice(i, 1);
        }
      }
    });
    this.pcSpecLstFromDB = pcSpecLst;
    console.log(this.pcSpecLstFromDB);
    console.log(this.selectedPcSpecLst);
    this.dummyPcSpecLst = ""
    let arrayvalue: any;
    arrayvalue = this.dummyPcSpecLstToDB.sort();
    console.log(arrayvalue.length, arrayvalue);
    let str = '';
    for (let i = 0; i < arrayvalue.length; i++) {
      if (str == '') {
        str = arrayvalue[i]
      } else {
        str += ',' + arrayvalue[i]
      }
      this.copyNetSpec = str
      console.log(this.copyNetSpec);

    }

  }

  movePcSpecBackward(): void {

    this.dummyPcSpecLstToDB.forEach(pcSPec => {
      let body = {
        "pc_spec": pcSPec
      }
      this.pcSpecLstFromDB.push(body);
      this.index = this.selectedPcSpecLst.indexOf(pcSPec);
      this.selectedPcSpecLst.splice(this.index, 1);
    });
    if (this.dummyPcSpecLstToDB.length >= 1) {
      this.pcSpecLstFromDB = this.pcSpecLstFromDB.sort((a, b) => a.pc_spec > b.pc_spec ? 1 : -1);
      console.log(this.pcSpecLstFromDB)
      console.log(this.selectedPcSpecLst);
      this.dummyPcSpecLstToDB = [];
      let arrayvalue: any = [];
      arrayvalue = this.selectedPcSpecLst.sort((a, b) => a.pc_spec > b.pc_spec ? 1 : -1);
      console.log(arrayvalue.length, arrayvalue);
      let str = '';
      for (let i = 0; i < arrayvalue.length; i++) {
        if (str == '') {
          str = arrayvalue[i]
        } else {
          str += ',' + arrayvalue[i]
        }
        this.copyNetSpec = str
        console.log(this.copyNetSpec);
        this.getnetSpec()
      }
    }
  }


  //compPcSPec
  moveCompPcSpecForward(): void {
    let compPcSpeclst = this.comppcSpecLstFromDB
    console.log(this.dummyCompPcSpecLst, compPcSpeclst);
    this.dummyCompPcSpecLst.forEach(compPcSpec => {
      this.selectedCompPcSpecLst.push(compPcSpec);
      for (let i = 0; i < compPcSpeclst.length; i++) {
        console.log(compPcSpec == compPcSpeclst[i].pc_spec)
        if (compPcSpec == compPcSpeclst[i].pc_spec) {
          compPcSpeclst.splice(i, 1);
        }
      }
    });
    this.comppcSpecLstFromDB = compPcSpeclst;
    this.dummyCompPcSpecLst = ""
    this.format()

  }

  format() {
    let array = []
    array = JSON.parse(JSON.stringify(this.selectedPcSpecLst))
    console.log(array);
    let myArray = array.filter(el => {
      return !this.selectedCompPcSpecLst.includes(el);
    });
    console.log('myArray', myArray);
    let sortnetspec = myArray.sort((a, b) => a.pc_spec > b.pc_spec ? 1 : -1)
    this.copyNetSpec = sortnetspec.join(',');
    console.log(this.copyNetSpec);
  }

  getnetSpec() {
    let pcSpecLst = this.pcSpecLstFromDB
    console.log(this.dummyPcSpecLstToDB);
    if (this.dummyPcSpecLstToDB.length != 0 || this.dummyPcSpecLstToDB != undefined) {
      this.dummyPcSpecLstToDB.forEach(pcSPec => {
        for (let i = 0; i < pcSpecLst.length; i++) {
          if (pcSPec == pcSpecLst[i].pc_spec) {
            pcSpecLst.splice(i, 1);
          }
        }
      });
      this.pcSpecLstFromDB = pcSpecLst;
      this.copyNetSpec = this.netSpecData
    }
  }


  moveCompPcSpecBackward(): void {
    this.dummyCompPcSpecLstToDB.forEach(compPcSpec => {
      let body = {
        "pc_spec": compPcSpec
      }
      this.comppcSpecLstFromDB.push(body);
      this.index = this.selectedCompPcSpecLst.indexOf(compPcSpec);
      this.selectedCompPcSpecLst.splice(this.index, 1);
    });
    console.log(this.comppcSpecLstFromDB)
    console.log(this.selectedCompPcSpecLst);
    if (this.dummyCompPcSpecLstToDB.length >= 1) {
      this.comppcSpecLstFromDB = this.comppcSpecLstFromDB.sort((a, b) => a.pc_spec > b.pc_spec ? 1 : -1);
      this.dummyCompPcSpecLstToDB = [];
    }
  }

  //save
  submitRepVeh(): void {
    let mnthly_flg; let compbom_flg; let HundPerSpec_flg; let compHundPerSpec_flg;
    if (!this.copyNamc || !this.copyFamily || !this.copyKatashiki
      || !this.copyCustomer || !this.copyExtTimeIn || !this.copySP) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.copyExtTimeIn && this.copyExtTimeOut &&
      this.pipe.transform(this.copyExtTimeIn, 'yyyy-MM-dd') > this.pipe.transform(this.copyExtTimeOut, 'yyyy-MM-dd')) {
      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";
    }
    else if (this.selectedPcSpecLst.length > 1) {
      let word, digits;
      let word1, digits1;
      let spclarray = [];
      let str1; let str2;
      let datafinal
      for (let i = 0; i < this.selectedPcSpecLst.length; i++) {
        for (let j = i + 1; j < this.selectedPcSpecLst.length; j++) {
          str1 = this.selectedPcSpecLst[i];
          [word, digits] = str1.match(/\D+|\d+/g);
          console.log(word, digits)
          str2 = this.selectedPcSpecLst[j];
          [word1, digits1] = str2.match(/\D+|\d+/g);
          console.log(word, digits)
          if (word == word1) {
            spclarray.push(str1, str2)
            datafinal = [...new Set(spclarray)]
          }
          // else {
          //   this.elsechsckfn();
          // }
        }
      }
      console.log("digits", word);
      console.log("spclarray", spclarray);
      console.log("spclarray", datafinal);
      if (spclarray.length > 0) {
        this.errorMessage = "Pc Specs " + datafinal + " are Incompatible";
      } else {
        this.elsechsckfn();
      }
    }
    else {
      this.elsechsckfn();
    }
  }
  elsechsckfn() {
    let fileName= localStorage.getItem('Filename')
    let HundPerSpec_flg;
    if (this.copyHundPercentSpec == true) {
      HundPerSpec_flg = 'Y'
    }
    else {
      HundPerSpec_flg = 'N'
    }
    if (this.copySP == 'P') {
      let pcspeccomppclst = []
      console.log("pcspeccomppclst", this.selectedPcSpecLst, this.selectedCompPcSpecLst)
      for (let i = 0; i < this.selectedPcSpecLst.length; i++) {
        for (let j = i; j < this.selectedCompPcSpecLst.length; j++) {
          console.log(this.selectedPcSpecLst[i] == this.selectedCompPcSpecLst[j]);
          if (this.selectedPcSpecLst[i] == this.selectedCompPcSpecLst[j]) {
            pcspeccomppclst.push(this.selectedPcSpecLst[i]);
          }
        }
      }
      console.log("pcspeccomppclst", pcspeccomppclst)
      if (((this.selectedCompPcSpecLst.length == pcspeccomppclst.length) && (this.selectedPcSpecLst.length == pcspeccomppclst.length)) && (this.selectedCompPcSpecLst.length != 0 && pcspeccomppclst.length != 0)) {
        console.log("number", this.selectedCompPcSpecLst.length, pcspeccomppclst.length)
        this.errorMessage = "PC Spec and Comp Pc Spec cannot be same"
      } else {
        let hikiatelstArray: any;
        hikiatelstArray = this.selectedHikiateLst;
        console.log(hikiatelstArray.length, hikiatelstArray);
        let str = '';
        for (let i = 0; i < hikiatelstArray.length; i++) {
          if (str == '') {
            str = hikiatelstArray[i]
          } else {
            str += ',' + hikiatelstArray[i]
          }
          // this.hikiatevalue = str;
        }
        this.hikiatevalue = str;
        console.log(this.hikiatevalue)
        let PcSpecLstArray: any;
        PcSpecLstArray = this.selectedPcSpecLst;
        console.log(PcSpecLstArray.length, PcSpecLstArray);
        let str1 = '';
        for (let i = 0; i < PcSpecLstArray.length; i++) {
          if (str1 == '') {
            str1 = PcSpecLstArray[i]
          } else {
            str1 += ',' + PcSpecLstArray[i]
          }

        }
        this.PcSpecvalue = str1;
        let CompPcSpecLstArray: any;
        CompPcSpecLstArray = this.selectedCompPcSpecLst;
        console.log(CompPcSpecLstArray.length, CompPcSpecLstArray);
        let str2 = '';
        for (let i = 0; i < CompPcSpecLstArray.length; i++) {
          if (str2 == '') {
            str2 = CompPcSpecLstArray[i]
          } else {
            str2 += ',' + CompPcSpecLstArray[i]
          }

        }
        let comppsspec = str2
        console.log(comppsspec)
        let indateFormat = ''; let outdateFormat = '';
        if (this.copyExtTimeIn != "") {
          let d1 = moment(this.copyExtTimeIn).format('YYYY-MM-DD')
          let b = d1.split('T')[0]
          let parts = []
          parts = b.split('-');
          let date = new Date(parts[0], parts[1] - 1, parts[2]);
          indateFormat = moment(date).format('YYYY-MM-DD')
          this.copyExtTimeIn = date
          // let date = this.pipe.transform(this.effDateIn, 'yyyy-MM-dd')
          // this.effDateIn = date
          // console.log("this.effDateIn",date,this.effDateIn);

        }
        if (this.copyExtTimeOut != "") {
          let d1 = moment(this.copyExtTimeOut).format('YYYY-MM-DD')
          let b = d1.split('T')[0]
          let parts = []
          parts = b.split('-');
          let date = new Date(parts[0], parts[1] - 1, parts[2]);
          outdateFormat = moment(date).format('YYYY-MM-DD')
          this.copyExtTimeOut = date
          // this.effDateOut = this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')
        }
        if (this.copyDest == null) {
          this.copyDest = ""
        }
        this.CompPcSpecvalue = str2;
        let body = {
          'namc': this.copyNamc,
          'family': this.copyFamily,
          'katashiki': this.copyKatashiki,
          'custId': this.copyCustomer,
          'dest': this.copyDest,
          'hikiate': this.hikiatevalue,
          // 'pcSpec': this.PcSpecvalue,
          // 'hunSpec': HundPerSpec_flg,
          'extTimeIn': indateFormat,
          'extTimeOut': outdateFormat,
          'compPcSpec': comppsspec,
          'cmpHunPctOptFlg': this.copyCompHundPercentSpec == true ? 'Y' : 'N',
          // 'sp': this.copySP,
          "screenNo":"NBPC_9350",
          "fileName" :fileName
        }
        console.log(body)
        this.SpinnerService.show();
        this.repVehCopyAddService.ValbaseOptTypVal(body).subscribe(data => {
          console.log(data);
          this.SpinnerService.hide();
          this.response = data.payload;
          if (this.response[0].result == 'Base spec found and hence the spec p can be added') {
            this.CheckExistsAddRepVeh()
          } else if (this.response[0].result == 'Base spec not found and  the spec p cannot be added') {
            this.errorMessage = "Option Spec should be entered after Base Spec is defined";

          } else if (this.response[0].result == 'Invalid') {
            this.errorMessage = 'PC Spec and Comp PC Spec Invalid!';
          }
        }, err => {
          this.SpinnerService.hide();
        });
      }
    }
    else if (this.copySP = "S") {
      this.CheckExistsAddRepVeh();
    }

  }
  CheckExistsAddRepVeh() {
    let fileName= localStorage.getItem('Filename')
    let HundPerSpec_flg; let compHundPerSpec_flg;
    if (this.copyHundPercentSpec == true) {
      HundPerSpec_flg = 'Y'
    }
    else {
      HundPerSpec_flg = 'N'
    }
    if (this.copyCompHundPercentSpec == true) {
      compHundPerSpec_flg = 'Y'
    }
    else {
      compHundPerSpec_flg = 'N'
    }
    let hikiatelstArray: any;
    console.log(this.selectedHikiateLst)
    hikiatelstArray = this.selectedHikiateLst;
    console.log(hikiatelstArray.length, hikiatelstArray);
    let str = '';
    for (let i = 0; i < hikiatelstArray.length; i++) {
      if (str == '') {
        str = hikiatelstArray[i]
      } else {
        str += ',' + hikiatelstArray[i]
      }

    }
    this.hikiatevalue = str;
    let PcSpecLstArray: any;
    PcSpecLstArray = this.selectedPcSpecLst;
    console.log(PcSpecLstArray.length, PcSpecLstArray);
    let str1 = '';
    for (let i = 0; i < PcSpecLstArray.length; i++) {
      if (str1 == '') {
        str1 = PcSpecLstArray[i]
      } else {
        str1 += ',' + PcSpecLstArray[i]
      }

    }
    this.PcSpecvalue = str1;
    let CompPcSpecLstArray: any;
    CompPcSpecLstArray = this.selectedCompPcSpecLst;
    console.log(CompPcSpecLstArray.length, CompPcSpecLstArray);
    let str2 = '';
    for (let i = 0; i < CompPcSpecLstArray.length; i++) {
      if (str2 == '') {
        str2 = CompPcSpecLstArray[i]
      } else {
        str2 += ',' + CompPcSpecLstArray[i]
      }

    }
    this.CompPcSpecvalue = str2;
    let body = {
      'namc': this.copyNamc,
      'family': this.copyFamily,
      'katashiki': this.copyKatashiki,
      'custId': this.copyCustomer,
      'dest': this.copyDest,
      'hikiate': this.hikiatevalue,
      'pcSpec': this.PcSpecvalue,
      'compPcSpec': this.CompPcSpecvalue,
      'hunSpec': HundPerSpec_flg,
      'cmpHunSpec': compHundPerSpec_flg,
      'sp': this.copySP,
      "screenNo":"NBPC_9350",
      "fileName" :fileName
    }
    this.SpinnerService.show();
    this.repVehCopyAddService.CheckExistsRepVehicle(body).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.CopyAddRepVehicle();
      } else if (this.response[0].result == 'Invalid') {
        this.SpinnerService.hide();
        this.errorMessage = 'Part Number Invalid';
      } else if (this.response[0].result == 'Data Already Exists') {
        this.SpinnerService.hide();
        this.errorMessage = 'Record Already Exists!';
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }

  CopyAddRepVehicle() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    let mnthly_flg; let compbom_flg; let HundPerSpec_flg; let compHundPerSpec_flg;
    if (this.copyExtTimeOut == "") {
      this.copyExtTimeOut = "9999-12-31"
    }
    let indateFormat = ''; let outdateFormat = '';
    if (this.copyExtTimeIn != "") {
      let d1 = moment(this.copyExtTimeIn).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.copyExtTimeIn = date
      // let date = this.pipe.transform(this.effDateIn, 'yyyy-MM-dd')
      // this.effDateIn = date
      // console.log("this.effDateIn",date,this.effDateIn);

    }
    if (this.copyExtTimeOut != "") {
      let d1 = moment(this.copyExtTimeOut).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.copyExtTimeOut = date
      // this.effDateOut = this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')
    }
    if (this.copyMonthlyRep == true) {
      mnthly_flg = 'Y'
    }
    else {
      mnthly_flg = 'N'
    }
    if (this.copyCompBom == true) {
      compbom_flg = 'Y'
    }
    else {
      compbom_flg = 'N'
    }
    if (this.copyHundPercentSpec == true) {
      HundPerSpec_flg = 'Y'
    }
    else {
      HundPerSpec_flg = 'N'
    }
    if (this.copyCompHundPercentSpec == true) {
      compHundPerSpec_flg = 'Y'
    }
    else {
      compHundPerSpec_flg = 'N'
    }
    let hikiatelstArray: any;
    hikiatelstArray = this.selectedHikiateLst;
    console.log(hikiatelstArray.length, hikiatelstArray);
    let str = '';
    for (let i = 0; i < hikiatelstArray.length; i++) {
      if (str == '') {
        str = hikiatelstArray[i]
      } else {
        str += ',' + hikiatelstArray[i]
      }

    }
    this.hikiatevalue = str;
    let PcSpecLstArray: any;
    PcSpecLstArray = this.selectedPcSpecLst;
    console.log(PcSpecLstArray.length, PcSpecLstArray);
    let str1 = '';
    for (let i = 0; i < PcSpecLstArray.length; i++) {
      if (str1 == '') {
        str1 = PcSpecLstArray[i]
      } else {
        str1 += ',' + PcSpecLstArray[i]
      }

    }
    this.PcSpecvalue = str1;
    let CompPcSpecLstArray: any;
    CompPcSpecLstArray = this.selectedCompPcSpecLst;
    console.log(CompPcSpecLstArray.length, CompPcSpecLstArray);
    let str2 = '';
    for (let i = 0; i < CompPcSpecLstArray.length; i++) {
      if (str2 == '') {
        str2 = CompPcSpecLstArray[i]
      } else {
        str2 += ',' + CompPcSpecLstArray[i]
      }

    }
    this.CompPcSpecvalue = str2;
    console.log(this.copyDest, this.PcSpecvalue)
    this.errorMessage = "";
    let cmts;

    if (this.copyComments != undefined) {

      cmts = this.copyComments.replaceAll("'", "''");

    } else {

      cmts = this.copyComments

    }
    let body = {
      'monRepFlg': mnthly_flg,
      'compBomFlg': compbom_flg,
      'mdlNo': this.copyModelNumber,
      'optCd': this.copyOption,
      'sapMtrlId': this.copySapMatId,
      'namc': this.copyNamc,
      'family': this.copyFamily,
      'katashiki': this.copyKatashiki,
      'custId': this.copyCustomer,
      'dest': this.copyDest,
      'sp': this.copySP,
      'hikiate': this.hikiatevalue,
      'pcSpec': this.PcSpecvalue,
      'compPcSpec': this.CompPcSpecvalue,
      'netSpec': this.copyNetSpec,
      'hunSpec': HundPerSpec_flg,
      'cmpHunSpec': compHundPerSpec_flg,
      'extTimeIn': indateFormat,
      'extTimeOut': outdateFormat,
      'comment': cmts,
      "screenNo":"NBPC_9350",
      "fileName" :fileName
      

    }
    console.log(body);
    this.SpinnerService.show();
    this.repVehCopyAddService.addRepVehicleData(body).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
      } else if (this.response[0].result == 'AlreadyExists') {
        this.errorMessage = 'Record already Exists!';
      } else if (this.response[0].result == 'Base Spec not found') {
        this.errorMessage = 'Base Spec not found!';
      } else if (this.response[0].result == 'PCSpec and Compspec are same') {
        this.errorMessage = 'PC Spec and Comp PC Spec are same!';
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }

  //cancel
  cancel(): void {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  //closealert
  closeAlert(): void {
    this.errorMessage = '';

  }

  getCopyCompSpec() {
    console.log(this.copySP)
    if (this.copySP == "S") {
      console.log(this.hidedata)
      this.hidedata = "true";
      this.selectedCompPcSpecLst = []
    } else {
      this.hidedata = "false";
      console.log(this.hidedata)

    }
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'copyExtTimeIn') {
      this.copyExtTimeIn = "";
    } else if (name == 'copyExtTimeOut') {
      this.copyExtTimeOut = "";
    }
  }
}
