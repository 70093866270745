<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>
    Edit <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

  <mat-dialog-content class="mat-typography">
    <div class="container">
      <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Approval Status:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="verEditApprovalStat" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Version Type:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="verEditVerType" disabled></div>
          </div>
        </div>
      </div>
      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Version Qualifier:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="verEditVerQual" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Version Revision:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="verEditVerRev" disabled></div>
          </div>
        </div>
      </div>

      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Priority<span class="text-danger">*</span>:</label></div>
            <div class=col-8>
              <select class="heightadd" [(ngModel)]="verEditPriority">
                <option value=""></option>
                <option *ngFor="let data of priorityArray" [(ngValue)]="data.ver_priority_typ_id">
                  {{data.ver_priority_typ_cd}}
                </option>

              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Volume Count Status:</label></div>
            <div class=col-8><input [(ngModel)]="verEditVolCntStatus" class="inputtext" type="text" disabled></div>
          </div>
        </div>
      </div>

      <!-- row4 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
             <div class="col-4"> <label>BoM Effectivity Date:</label></div>
            <div class=col-8>
              
				<input class="heightadd dateadd inputtext" [(ngModel)]="verEditBomEffDate" type="text" disabled>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Cost Effectivity Date:</label></div>
            <div class=col-8>
              <input class="heightadd dateadd inputtext" [(ngModel)]="verEditCostEffDate" type="text" disabled>
            </div>
          </div>
        </div>
      </div>

      <!-- row5 -->

      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Effective In Date<span class="text-danger">*</span>:</label></div>
          <div class=col-8>
            <mat-form-field class="inputtext" appearance="outline">
              <input (change)="dateChange1($event.target.value)" matInput [matDatepicker]="bomEffDatepicker" [(ngModel)]="verEditEffInDate" >
              <mat-datepicker-toggle matSuffix [for]="bomEffDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #bomEffDatepicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'verEditEffInDate')">clear</mat-icon>
              </mat-form-field>          </div>          
			  </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Effective Out Date<span class="text-danger">*</span>:</label></div>
            <div class=col-8>
              <mat-form-field class="inputtext" appearance="outline">
                <input (change)="dateChange($event.target.value)" matInput [matDatepicker]="effOutDatepicker" [(ngModel)]="verEditEffOutDate">
                <mat-datepicker-toggle matSuffix [for]="effOutDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #effOutDatepicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'verEditEffOutDate')">clear</mat-icon>

              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <!-- row13 -->
      <div class="row rowClass">
        <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
        <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8">
          <textarea class="w-100" [(ngModel)]="verEditComments" name="w3review" rows="2" cols="50">
          </textarea>
        </div>
      </div>

    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-button cdkFocusInitial (click)="updateVersion()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>
